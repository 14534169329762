// store.js
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session"; // Defaults to localStorage for web

import { rootReducer } from "./reduxConfig/rootReducer"; // Import your rootReducer

const persistConfig = {
  key: "root", // Key for the persisted data
  storage,
  // Add any other configuration options here if required
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
