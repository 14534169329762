import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  invoiceData: {},
};

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    handleInvoiceData: (state, action) => {
      state.invoiceData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { handleInvoiceData } = invoiceSlice.actions;

export default invoiceSlice.reducer;
