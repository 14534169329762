import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  truckingType: "trucking",
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    handleTruckingType: (state, action) => {
      state.truckingType = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { handleTruckingType } = settingSlice.actions;

export default settingSlice.reducer;
